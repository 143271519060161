import React from "react";
import { Col, Grid, Row } from "react-bootstrap";
import "../assets/sass/lbd/_dashboard.scss";
import { WithAuth } from "../components/Authorization/WithAuth";
import AppointmentWidget from "../components/Dashboard/AppointmentWidget.jsx";
import DateTimeWidget from "../components/Dashboard/DateTimeWidget.jsx";
import PatientWidget from "../components/Dashboard/PatientWidget.jsx";
import SearchWidget from "../components/Dashboard/SearchWidget.jsx";
import WelcomeWidget from "../components/Dashboard/WelcomeWidget.jsx";
import UsersNotifications from "../components/Notifications/UserNotifications.jsx";
import WaitingListWidgetContainer from "../containers/Agenda/WaitingListWidgetContainer.jsx";
import SignalRProvider from "../services/signalRService";
import { useSelector } from 'react-redux';

export const Dashboard = () => {

  const user = useSelector(state => state.authentication.user);
  const settingsState = useSelector(state => state.settings);

  return (
    <Grid fluid className="dashboard">
      <Row className="display-flex" style={{ marginBottom: '50px' }}>
        <Col md={4}>
          <WelcomeWidget />
          <WithAuth requiredPermission={["Database.List"]}>
            <SearchWidget />
          </WithAuth>
        </Col>
        <Col md={5}>
          <div className="buttons-widget">
            <Row>
              <WithAuth requiredPermission={["Agenda.Create"]}>
                <Col md={6}>
                  <AppointmentWidget />
                </Col>
              </WithAuth>
              <WithAuth requiredPermission={["Database.Create"]}>
                <Col md={6}>
                  <PatientWidget />
                </Col>
              </WithAuth>
            </Row>
          </div>
        </Col>
        <Col md={3}>
          <DateTimeWidget />
        </Col>
      </Row>
      <Row>
        <WithAuth>
          <SignalRProvider groupName={user?.sub} settings={settingsState.settings}>
            <WithAuth requiredPermission={["MedicalRecords.Create"]}>
              <Col md={6}>
                <WaitingListWidgetContainer />
              </Col>
            </WithAuth>
            <Col md={6}>
              <WithAuth requiredPermission={["MedicalRecords.View"]}>
                <UsersNotifications />
              </WithAuth>
            </Col>
          </SignalRProvider>
        </WithAuth>
      </Row>
    </Grid>
  );
}

export default Dashboard;
