import React from 'react';
import { Label } from 'react-bootstrap'
import moment from 'moment';
import { IconSVG } from '../Icons/Icons';
import { patientInProtocolStatus, visitStatus } from '../../variables/Enums';
import i18n from '../../i18n';

export const nullableDateFormatter = (day, month, year) => {

  if (!year)
    return null;

  return `${day ?? "unk"}/${month ? parseInt(month) + 1 : "unk"}/${year}`;
}

export const toLocaleDateFormatter = (date, format, withIcon) => {
  if (!format)
    format = 'DD/MM/YYYY';

  const icon = withIcon ? <IconSVG name="date" /> : null;

  if (!date)
    return (<span className="date-formatter"> </span>);

  try {
    // date.length > 30 entonces ya tiene el locale del UI.
    var localeDate = date.endsWith("Z") || date.length > 30 ? new Date(date) : new Date(date + "Z");
    return (<span className="date-formatter">{icon} {moment(localeDate).format(format)}</span>);
  }
  catch (error) {
    return (<span className="date-formatter">{icon} {moment(date).format(format)} UTC</span>);
  }
}

export const dateFormatter = (dateTime, format, withIcon) => {
  if (!format)
    format = 'DD/MM/YYYY';

  const icon = withIcon ? <IconSVG name="date" /> : null;

  if (dateTime) {
    return (<span className="date-formatter">{icon} {moment(dateTime).locale("es").format(format)} </span>);
  }

  return (<span className="date-formatter"> </span>);
}

export const labelFormatter = (value, style) => {

  if (!style)
    style = "default";
  if (value)
    return (<Label bsStyle={style}>{value}</Label>);

  return null;
}

export function toLocaleDateTimeFormatter(dateTime, format = 'DD/MM/YYYY HH:mm') {
  if (!dateTime)
    return (<span className="datetime-formatter"> </span>);

  try {
    var date = dateTime.endsWith("Z") ? new Date(dateTime) : new Date(dateTime + "Z");
    return (<span className="datetime-formatter"> {moment(date).format(format)}</span>);
  }
  catch (error) {
    console.log(error, dateTime)
    return (<span className="datetime-formatter"> {moment(dateTime).format(format)} UTC</span>);
  }
}

export const dateTimeFormatter = (dateTime) => {
  if (dateTime)
    return (<span className="datetime-formatter"> {moment(dateTime).format('DD/MM/YYYY HH:mm')} </span>);

  return (<span className="datetime-formatter"> </span>);
}

export const dateTimeUTCFormatter = (dateTime) => {
  if (dateTime)
    return (<span className="datetime-formatter"> {moment.utc(dateTime).local().format('DD/MM/YYYY HH:mm')} </span>);

  return (<span className="datetime-formatter"> </span>);
}

export const timeFormatter = (time) => {
  if (time)
    return (<span className="datetime-formatter"> {moment(time).format('HH:mm')} </span>);

  return (<span className="datetime-formatter"> </span>);
}

export const boolFormatter = (value, translationFn) => {

  if (value === null || value === undefined || !translationFn)
    return "";

  if (value === true)
    return (translationFn('commons.yes'));
  if (value === false)
    return (translationFn('commons.no'));

  return "";
}

export const yesNoFormatter = (value, translationFn) => {

  if (value === null || value === undefined || !translationFn)
    return "";

  if (value === 'yes' || value)
    return translationFn('commons.yes')

  return translationFn('commons.no');
}

export const yesNoNotApplyFormatter = (value, translationFn) => {

  if (value === null && value === undefined || !translationFn)
    return "";

  switch (value) {
    case "yes": return translationFn('commons.yes');
    case "no": return translationFn('commons.no');
    case "n/a": return translationFn('commons.na');
    default:
      return "";
  }
}

export const YesNoFormatter = (cell, _row, _rowIndex, formatExtraData) => {

  return (
    <div>
      {
        cell === true ?
          formatExtraData.t('commons.yes') :
          formatExtraData.t('commons.no')
      }
    </div>
  );
}

export const objectFormatter = (value) => {

  if (value === null || value === undefined)
    return ""

  if (value.hasOwnProperty("ConceptId")) {
    return value["Description"];
  }
  if (value.hasOwnProperty("Code")) {
    return value["Title"];
  }

  return "";
}

export const attachmentFormatter = (value) => {
  if (value === null || value === undefined)
    return ""

  if (value.hasOwnProperty("FileName")) {
    return value["FileName"];
  }
  if (value.hasOwnProperty("ValidatedBy")) {
    return "Validado";
  }

  return "";
}

export const extractContentFromHTML = (html) => {
  return new DOMParser().parseFromString(html, "text/html").documentElement.textContent;
}

export const visitStatusFormatter = (status) => {
  if (status === null || status === undefined)
    return "";

  switch (status) {
    case visitStatus.Started:
      return (<Label bsStyle="info">{i18n.t("protocols.visit.status.started")}</Label>);
    case visitStatus.Completed:
      return (<Label bsStyle="success">{i18n.t("protocols.visit.status.completed")}</Label>);
    case visitStatus.Skipped:
      return (<Label bsStyle="warning">{i18n.t("protocols.visit.status.skipped")}</Label>);
    case visitStatus.Failed:
      return (<Label bsStyle="danger">{i18n.t("protocols.visit.status.failed")}</Label>);
    case visitStatus.Changed:
      return (<Label bsStyle="warning">{i18n.t("protocols.visit.status.changed")}</Label>);
    case visitStatus.AutoCompleted:
      return (<Label className="montoto">{i18n.t("protocols.visit.status.autoCompleted")}</Label>);
    default:
      return "";
  }
}

export const patientVisitStatusFormatter = (status) => {
  if (status === null || status === undefined)
    return "";

  switch (status) {
    case patientInProtocolStatus.Screening:
      return (<Label bsStyle="info">{i18n.t('protocols.patient.statuses.screening')}</Label>);
    case patientInProtocolStatus.Random:
      return (<Label bsStyle="success">{i18n.t('protocols.patient.statuses.random')}</Label>);
    case patientInProtocolStatus.Treatment:
      return (<Label bsStyle="success">{i18n.t('protocols.patient.statuses.treatment')}</Label>);
    case patientInProtocolStatus.FollowUp:
      return (<Label bsStyle="success">{i18n.t('protocols.patient.statuses.followUp')}</Label>);
    case patientInProtocolStatus.Failed:
      return (<Label bsStyle="danger">{i18n.t('protocols.patient.statuses.failed')}</Label>);
    case patientInProtocolStatus.LostToFollowUp:
      return (<Label bsStyle="danger">{i18n.t('protocols.patient.statuses.lostToFollowUp')}</Label>);
    case patientInProtocolStatus.WithoutDrugs:
      return (<Label bsStyle="info">{i18n.t('protocols.patient.statuses.withoutDrugs')}</Label>);
    case patientInProtocolStatus.Discontinuation:
      return (<Label bsStyle="danger">{i18n.t('protocols.patient.statuses.discontinuation')}</Label>);
    case patientInProtocolStatus.EndParticipationInProtocol:
      return (<Label bsStyle="info">{i18n.t('protocols.patient.statuses.endParticipationInProtocol')}</Label>);
    default:
      return "";
  }
}