import { Agenda } from "./containers/Agenda/Agenda";
import { CreatePatientContainer } from "./containers/Database/CreatePatient";
import { EditPatientContainer } from "./containers/Database/EditPatient";
import { PatientContainer } from "./containers/Database/Patient";
import { ExternalNotesContainer } from "./containers/ExternalNotes/ExternalNotes";
import CreateFreezerContainer from "./containers/Freezers/FreezerComponents/CreateFreezerContainer";
import EditFreezerContainer from "./containers/Freezers/FreezerComponents/EditFreezerContainer";
import FreezersContainer from "./containers/Freezers/FreezerComponents/FreezersContainer";
import CreateIotDeviceContainer from "./containers/Freezers/IotDevicesComponents/CreateDeviceContainer";
import DeviceDetails from "./containers/Freezers/IotDevicesComponents/DeviceDetails";
import IotDevicesContainers from "./containers/Freezers/IotDevicesComponents/IotDevicesContainer";
import CreateEntryContainer from "./containers/MedicalRecords/CreateEntry";
import MedicalRecordContainer from "./containers/MedicalRecords/MedicalRecord";
import { ViewEntryContainer } from "./containers/MedicalRecords/ViewEntry";
import { CreateLaboratoryContainer } from "./containers/Protocols/Admin/CreateLaboratory";
import { CreateStudyTaskContainer } from "./containers/Protocols/Admin/CreateStudyTasks";
import { EditLaboratoryContainer } from "./containers/Protocols/Admin/EditLaboratory";
import { EditStudyTaskContainer } from "./containers/Protocols/Admin/EditStudyTasks";
import { LaboratoriesContainer } from "./containers/Protocols/Admin/Laboratories";
import { StudyTasksContainer } from "./containers/Protocols/Admin/StudyTasks";
import { ExtraForm } from "./containers/Protocols/ExtraForms/ExtraForm";
import ProtocolContainer from "./containers/Protocols/Protocol";
import { Visit } from "./containers/Protocols/Visits/Visit";
import { VisitForm } from "./containers/Protocols/Visits/VisitForm";
import { CreateRoleContainer } from "./containers/Security/Authorization/CreateRole";
import { CreateStudyRoleContainer } from "./containers/Security/Authorization/CreateStudyRole";
import { EditRoleContainer } from "./containers/Security/Authorization/EditRole";
import { EditStudyRoleContainer } from "./containers/Security/Authorization/EditStudyRole";
import { RolesContainer } from "./containers/Security/Authorization/Roles";
import { StudyRolesContainer } from "./containers/Security/Authorization/StudyRoles";
import { CreateTenantContainer } from "./containers/Tenants/Create";
import { EditTenantContainer } from "./containers/Tenants/Edit";
import { TenantsContainer } from "./containers/Tenants/Tenants";
import { CreateUserContainer } from "./containers/Users/CreateUser";
import { EditUserContainer } from "./containers/Users/EditUser";
import { UserContainer } from "./containers/Users/User";
import { UserSettingsContainer } from "./containers/Users/UserSettings";
import { UsersContainer } from "./containers/Users/Users";
import Dashboard from "./views/Dashboard";
import DatabaseHome from './views/Database';
import ForbiddenPage from "./views/Errors/Forbidden";
import NotFoundPage from "./views/Errors/NotFound";
import UnauthorizedPage from "./views/Errors/Unauthorized";
import { ExtraVisitTrackingView } from "./views/Protocols/ExtraVisitTrackingView";
import { PractitionersView } from "./views/Protocols/PractitionersView";
import { ProtocolCreateView } from "./views/Protocols/ProtocolCreateView";
import { ProtocolEditView } from "./views/Protocols/ProtocolEditView";
import { ProtocolsView } from "./views/Protocols/ProtocolsView";
import { VisitTrackingView } from "./views/Protocols/VisitTrackingView";
import Settings from "./views/Settings";
import { TelemetryPage } from "./views/TelemetryPage";
import UserProfile from "./views/UserProfile";

const routes = [
  {
    path: "/dashboard",
    name: "routes.dashboard",
    iconSVG: "dashboard",
    component: Dashboard,
    layout: "/admin"
  },
  // Agenda
  {
    path: "/agenda",
    name: "routes.agenda",
    iconSVG: "agenda",
    component: Agenda,
    layout: "/admin",
    permission: ["Agenda.List"],
  },
  // Patients
  {
    collapse: true,
    displayChilds: false,
    path: "/database",
    name: "routes.database",
    iconSVG: "patients",
    layout: "/admin",
    permission: ["Database.List"],
    component: DatabaseHome,
    routes: [
      {
        path: "/database",
        name: "Pacientes",
        mini: "P",
        layout: "/admin",
        component: DatabaseHome,
        hidden: true
      },
      {
        path: "/database/create",
        name: "Nuevo Paciente",
        mini: "NP",
        layout: "/admin",
        component: CreatePatientContainer,
        hidden: true
      },
      {
        path: "/database/:medicalRecordNumber",
        name: "Ver Paciente",
        mini: "VP",
        layout: "/admin",
        component: PatientContainer,
        hidden: true
      },
      {
        path: "/database/:medicalRecordNumber/edit",
        name: "Editar Paciente",
        mini: "EP",
        layout: "/admin",
        component: EditPatientContainer,
        hidden: true
      },
    ]
  },
  // Medical Records
  {
    collapse: true,
    displayChilds: false,
    path: "/medicalRecords",
    name: "routes.medicalRecords",
    iconSVG: "medicalRecords",
    component: MedicalRecordContainer,
    layout: "/admin",
    permission: ["MedicalRecords.List"],
    hidden: true,
    routes: [
      {
        path: "/medicalRecords/:medicalRecordNumber",
        name: "Historia Clínica",
        mini: "HC",
        layout: "/admin",
        component: MedicalRecordContainer,
        hidden: true
      },
      {
        path: "/medicalRecords/:medicalRecordNumber/newentry/:entryId",
        name: "Nueva Entrada",
        mini: "NE",
        layout: "/admin",
        component: CreateEntryContainer,
        hidden: true
      },
      {
        path: "/medicalRecords/:medicalRecordNumber/entry/:entryId",
        name: "Entrada",
        mini: "VE",
        layout: "/admin",
        component: ViewEntryContainer,
        hidden: true
      }
    ]
  },
  // Protocols
  {
    collapse: true,
    displayChilds: false,
    path: "/protocols",
    name: "routes.protocols",
    iconSVG: "protocols",
    layout: "/admin",
    permission: ["Protocols.List"],
    routes: [
      {
        path: "/protocols",
        name: "Protocolos",
        mini: "P",
        layout: "/admin",
        component: ProtocolsView,
        hidden: false
      },
      {
        path: "/protocols/create",
        name: "Nuevo Protocolo",
        mini: "NP",
        layout: "/admin",
        component: ProtocolCreateView,
        hidden: true
      },
      {
        path: "/protocols/:protocolId",
        name: "Protocolo",
        mini: "P",
        layout: "/admin",
        component: ProtocolContainer,
        hidden: true
      },
      {
        path: "/protocols/:protocolId/section/:section",
        mini: "P",
        layout: "/admin",
        component: ProtocolContainer,
        hidden: true
      },
      {
        path: "/protocols/:protocolId/edit/",
        name: "Editar Protocolo",
        mini: "EP",
        layout: "/admin",
        component: ProtocolEditView,
        hidden: true
      },
      {
        path: "/protocols/:protocolId/practitioners",
        name: "Profesionales",
        mini: "AP",
        layout: "/admin",
        component: PractitionersView,
        hidden: true
      },
      // visitTracking
      {
        path: "/protocols/:protocolId/visitTracking/:visitId/patient/:patientId",
        name: "Visita Protocolar",
        mini: "VP",
        layout: "/admin",
        component: VisitTrackingView,
        hidden: true
      },
      {
        path: "/protocols/:protocolId/visitTrackingExtra/:visitId/patient/:patientId",
        name: "Visita No Programada",
        mini: "VEP",
        layout: "/admin",
        component: ExtraVisitTrackingView,
        hidden: true
      },
      {
        path: "/protocols/:protocolId/visitsTemplate/:visitId",
        name: "Hoja de Visita",
        mini: "VP",
        layout: "/admin",
        component: Visit,
        hidden: true
      },
      {
        path: "/protocols/:protocolId/visitsTemplate/:visitId/forms/create",
        name: "Crear formulario para hoja de visita",
        mini: "VP",
        layout: "/admin",
        component: VisitForm,
        hidden: true
      },
      {
        path: "/protocols/:protocolId/visitsTemplate/:visitId/forms/:formId",
        name: "Editar formulario para hoja de visita",
        mini: "VP",
        layout: "/admin",
        component: VisitForm,
        hidden: true
      },
      {
        path: "/protocols/:protocolId/extraforms/create/:extraformtype",
        name: "Crear formulario extra",
        mini: "EF",
        layout: "/admin",
        component: ExtraForm,
        hidden: true
      },
      {
        path: "/protocols/:protocolId/extraforms/:formId",
        name: "Editar formulario extra",
        mini: "EEF",
        layout: "/admin",
        component: ExtraForm,
        hidden: true
      },
    ]
  },
  // External Notes
  {
    path: "/externalnotes",
    name: "routes.externalnotes",
    iconSVG: "externalnote",
    layout: "/admin",
    permission: ["Protocols.List"],
    component: ExternalNotesContainer,
  },
  // Settings
  {
    collapse: true,
    path: "/settings",
    name: "routes.settings",
    state: "active",
    layout: "/admin",
    iconSVG: "settings",
    permission: ["Security.List", "Settings.List"],
    routes: [
      // Tenants
      {
        path: "/tenant",
        name: "Clientes",
        mini: "CL",
        layout: "/admin",
        component: TenantsContainer,
        permission: ["Tenant.List"],
        hidden: true
      },
      {
        path: "/tenant/create",
        name: "Crear Cliente",
        mini: "CLC",
        layout: "/admin",
        component: CreateTenantContainer,
        hidden: true
      },
      {
        path: "/tenant/:tenantId",
        name: "Editar Cliente",
        mini: "CLE",
        layout: "/admin",
        component: EditTenantContainer,
        hidden: true
      },

      {
        permission: ["Security.List", "Settings.List"],
        path: "/settings",
        name: "Configuración",
        mini: "C",
        layout: "/admin",
        component: Settings,
        hidden: true
      },
      {
        permission: ["Users.List"],
        path: "/users",
        name: "Usuarios",
        mini: "U",
        layout: "/admin",
        component: UsersContainer,
        hidden: true
      },
      {
        permission: ["Users.Create"],
        path: "/users/create",
        name: "Nuevo Usuario",
        mini: "NP",
        layout: "/admin",
        component: CreateUserContainer,
        hidden: true
      },
      {
        permission: ["Users.View"],
        path: "/users/:userId",
        name: "Usuario",
        mini: "VP",
        layout: "/admin",
        component: UserContainer,
        hidden: true
      },
      {
        permission: ["Users.Edit"],
        path: "/users/:userId/edit",
        name: "Editar",
        mini: "EP",
        layout: "/admin",
        component: EditUserContainer,
        hidden: true
      },
      {
        permission: ["Users.Edit"],
        path: "/users/:userId/completeProfile",
        name: "Perfíl de usuario",
        mini: "EP",
        layout: "/admin",
        component: UserProfile,
        hidden: true
      },
      {
        permission: ["Users.Edit"],
        path: "/users/:userId/config",
        name: "Configurar",
        mini: "C",
        layout: "/admin",
        component: UserSettingsContainer,
        hidden: true
      },
      {
        path: "/settings/roles",
        name: "Roles",
        mini: "R",
        layout: "/admin",
        component: RolesContainer,
        permission: ["Security.List"],
        hidden: true
      },
      {
        path: "/settings/roles/create",
        name: "Crear Rol",
        mini: "CR",
        layout: "/admin",
        component: CreateRoleContainer,
        hidden: true
      },
      {
        path: "/settings/roles/:roleId",
        name: "Editar Rol",
        mini: "ER",
        layout: "/admin",
        component: EditRoleContainer,
        hidden: true
      },
      // StudyRoles
      {
        path: "/settings/studyRoles",
        name: "Roles del Protocolo",
        mini: "RP",
        layout: "/admin",
        component: StudyRolesContainer,
        permission: ["Security.List"],
        hidden: true
      },
      {
        path: "/settings/studyRoles/create",
        name: "Crear Rol",
        mini: "CRP",
        layout: "/admin",
        component: CreateStudyRoleContainer,
        hidden: true
      },
      {
        path: "/settings/studyRoles/:roleId",
        name: "Editar Rol",
        mini: "ERP",
        layout: "/admin",
        component: EditStudyRoleContainer,
        hidden: true
      },
      // StudyTasks
      {
        path: "/settings/studyTasks",
        name: "Tareas del Protocolo",
        mini: "TP",
        layout: "/admin",
        component: StudyTasksContainer,
        permission: ["Settings.List"],
        hidden: true
      },
      {
        path: "/settings/studyTasks/create",
        name: "Crear Tarea Protocolar",
        mini: "CTP",
        layout: "/admin",
        component: CreateStudyTaskContainer,
        hidden: true
      },
      {
        path: "/settings/studyTasks/:studyTaskId",
        name: "Editar Tarea Protocolar",
        mini: "ETP",
        layout: "/admin",
        component: EditStudyTaskContainer,
        hidden: true
      },

      // Laboratories
      {
        path: "/settings/laboratories",
        name: "Laboratorios",
        mini: "TP",
        layout: "/admin",
        component: LaboratoriesContainer,
        permission: ["Settings.List"],
        hidden: true
      },
      {
        path: "/settings/laboratories/create",
        name: "Crear Laboratorio",
        mini: "CTP",
        layout: "/admin",
        component: CreateLaboratoryContainer,
        hidden: true
      },
      {
        path: "/settings/laboratories/:laboratoryId",
        name: "Editar Laboratorio",
        mini: "ETP",
        layout: "/admin",
        component: EditLaboratoryContainer,
        hidden: true
      },
      // Freezers
      {
        path: "/settings/freezers",
        name: "Heladeras",
        mini: "TP",
        layout: "/admin",
        component: FreezersContainer,
        permission: ["Freezers.List"],
        hidden: true
      },
      {
        path: "/settings/freezers/create",
        name: "Crear Heladera",
        mini: "CTP",
        layout: "/admin",
        component: CreateFreezerContainer,
        permission: ["Freezers.Create"],
        hidden: true
      },
      {
        path: "/settings/freezers/:freezerId",
        name: "Editar Heladera",
        mini: "ETP",
        layout: "/admin",
        component: EditFreezerContainer,
        permission: ["Freezers.Edit"],
        hidden: true
      },
      //Devices
      {
        path: "/settings/devices",
        name: "Dispositivos",
        mini: "TP",
        layout: "/admin",
        component: IotDevicesContainers,
        permission: ["Devices.List"],
        hidden: true
      },
      {
        path: "/settings/devices/create",
        name: "Crear Dispositivo",
        mini: "CTP",
        layout: "/admin",
        component: CreateIotDeviceContainer,
        permission: ["Devices.Create"],
        hidden: true
      },
      {
        path: "/settings/devices/detail/:deviceId",
        name: "Editar Dispositivo",
        mini: "ETP",
        layout: "/admin",
        component: DeviceDetails,
        permission: ["Devices.Edit"],
        hidden: true
      },
      //Telemetry
      {
        path: "/devicetelemetry",
        name: "Telemetria",
        mini: "T",
        layout: "/admin",
        component: TelemetryPage,
        permission: ["Telemetry.View", "Freezers.View"],
        hidden: true
      }

    ]
  },
  // Error Pages
  {
    path: "/unauthorized",
    name: "Error",
    layout: "/admin",
    component: UnauthorizedPage,
    hidden: true,
  },
  {
    path: "/forbidden",
    name: "Error",
    layout: "/admin",
    component: ForbiddenPage,
    hidden: true,
  },
  {
    path: "/not-found",
    name: "Error",
    layout: "/admin",
    component: NotFoundPage,
    hidden: true,
  },
  //Telemetry
  {
    path: "/devicetelemetry",
    name: "routes.telemetry",
    layout: "/admin",
    iconSVG: "telemetry",
    permission: ["Telemetry.View"],
    component: TelemetryPage
  }
];

export default routes;
