import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import React, { createContext, useContext, useEffect, useState } from 'react';
import authService from '../components/api-authorization/AuthorizeService';

const SignalRContext = createContext();

export const useSignalR = () => useContext(SignalRContext);

const SignalRProvider = ({ groupName, settings, children }) => {
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        const options = {
            logMessageContent: true,
            logger: LogLevel.Error,//isDev ? LogLevel.Warning : LogLevel.Error,
            accessTokenFactory: async () => {
                var access = await authService.getAccessToken();
                return access;
            }
        };

        const newConnection = new HubConnectionBuilder()
            .withUrl(`${settings.notificationsApi}/notificationhub`, options)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Error)
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start().then(() => {
                console.log("Connected to SignalR");
                connection.invoke("JoinGroup", groupName).catch(err => console.log(err));
            }).catch(err => console.log(err));
        }

        return () => {
            if (connection) {
                if (connection.connection._connectionState === HubConnectionState.Connected) {
                    console.log(groupName)
                    connection.invoke('LeaveGroup', "SystemNotifications").catch(err => console.log(err));
                    connection.invoke('LeaveGroup', groupName).catch(err => console.log(err));
                }
                connection.stop().then(() => { console.log("Connection stoped") }).catch(err => console.log(err));
            }
        };
    }, [connection]);

    return (
        <SignalRContext.Provider value={{ connection }}>
            {children}
        </SignalRContext.Provider>
    );
};

export default SignalRProvider;
