import React from "react";
import { Nav } from "react-bootstrap";
import { useSelector } from 'react-redux';
import SignalRProvider from "../../services/signalRService";
import { LoginMenu } from "../api-authorization/LoginMenu";
import AdminNavbarNotificationsContainer from "./AdminNavbarNotifications";
import { AdminNavbarUser } from "./AdminNavbarUser";
import { WithAuth } from "../Authorization/WithAuth";

const HeaderLinks = () => {
  const user = useSelector(state => state.authentication.user);
  const settingsState = useSelector(state => state.settings);

  return (
    <div>
      <Nav pullRight>
        {
          user &&
          <WithAuth>
            <SignalRProvider groupName={user?.sub} settings={settingsState.settings}>
              <AdminNavbarNotificationsContainer />
            </SignalRProvider>
          </WithAuth>
        }
        <LoginMenu />
        {
          user && <AdminNavbarUser user={user} />
        }
      </Nav>
    </div>
  );
}
export default HeaderLinks;
